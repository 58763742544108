/**
 * CodeManagerService
 *
 * This service handle some methods to manage codes in the application.
 */

import {checkCodeConditions} from "./ErrorCodeService";

/**
 *
 * @param codeList
 * @param type
 * @returns {boolean|*}
 *
 * Return all availables codes by game type
 */
export const getCodesByType = (codeList, type) => {
    if(codeList != null) {
        return codeList.filter(code => code.type === type);
    }
    return false;
};


/**
 *
 * @param code
 * @param codeList
 * @param executedCodes
 * @param opponents
 * @param player
 * @returns {boolean|{singleUse}|*}
 * Check if a code is available
 * If it is, return the code array
 * If not, return an object with checkCode status and datas
 */
export const checkCode = async (code, codeList, executedCodes, player, opponents) => {
    for (let i = 0; i < codeList.length; i++) {
        let item = codeList[i];
        if ((item.code.toLowerCase() === code.toLowerCase())) {
            let conditionsStatus = await checkCodeConditions(item.conditions, opponents, player);

            if(item.localisation != null && player != null && item.localisation !== player.localisation) {
                return {status: false, data: {message: "Ce code n'est pas disponible dans votre localisation."}}
            } else if(!conditionsStatus.status) {
                return conditionsStatus;
            } else if ((item.singleUse && !executedCodes.includes(item.code))) {
                return {status: true, data: item};
            } else if (!item.singleUse) {
                return {status: true, data: item};
            } else if (executedCodes.includes(item.code)) {
                return {status: false, data:{message: "Ce code n'est pas réutilisable."}}
            }
        }
    }

    return {status: false, data:{message: "Ce code n'existe pas."}};
};
