/**
 * Code Page
 *
 * This is one of the most important file of the framework. It contains the logic to display an input to handle codes entered by the users.
 * This page also display the story telling of the game (if the game has one).
 */

import * as React from "react";
import {connect} from "react-redux";
import {navigate} from "gatsby";
import {setCurrentCode} from "../redux/actions/global/setCurrentCode";
import ModalComponent from "../components/ModalComponent";
import DefaultLayoutComponent from "../components/layouts/DefaultLayoutComponent";
import {getCodesByType, checkCode} from "../services/utils/CodeManagerService";
import {setErrorCodeMessage} from "../redux/actions/global/setErrorCodeMessage";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import tutoImg from "../assets/images/pastille-intro.svg";
import romeImg from "../assets/images/pastille-rome.svg";
import provincesImg from "../assets/images/pastille-provinces.svg";
import {faQuestion} from "@fortawesome/free-solid-svg-icons";
import {StaticImage} from "gatsby-plugin-image";

const CodePage = ({isNewState, currentRound, totalRounds, roundsData, codeList, hasStoryTelling, executedCodes, gameType, player, opponents, dispatch}) => {
    const [inputCode, setInputCode] = React.useState('');
    const [confirmModalOpenState, setConfirmModalOpenState] = React.useState(false);
    const [storyModalOpenState, setStoryModalOpenState] = React.useState(false);
    const [opponentsModalOpenState, setOpponentsModalOpenState] = React.useState(false);
    const [playerModalOpenState, setPlayerModalOpenState] = React.useState(false);
    const [errorCodeModalOpenState, setErrorCodeModalOpenState] = React.useState(false);
    const [helpModalOpenState, setHelpModalOpenState] = React.useState(false);
    const [formColor, setFormColor] = React.useState('green');
    const [headImg, setHeadImg] = React.useState(tutoImg);
    const [displayDate, setDisplayDate] = React.useState('');

    React.useEffect(() => {
        if (isNewState) {
            navigate('/');
        }

        if (gameType !== "tuto" && gameType !== "profile") {
            if (player.localisation === "rome") {
                setFormColor("purple");
                setHeadImg(romeImg);
            } else {
                setFormColor("ocher");
                setHeadImg(provincesImg);
            }
        }

        if (gameType === "main" && roundsData[0].main != null) {
            let mainRoundsData = roundsData[0].main;
            setDisplayDate(mainRoundsData[currentRound - 1].date);
        }

        if(hasStoryTelling) {
            setStoryModalOpenState(true);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const toggleConfirmModal = () => {
        setConfirmModalOpenState(!confirmModalOpenState);
    };

    const toggleStoryModal = () => {
        setStoryModalOpenState(!storyModalOpenState);
    };

    const toggleOpponentsModal = () => {
        setOpponentsModalOpenState(!opponentsModalOpenState);
    };

    const togglePlayerModal = () => {
        setPlayerModalOpenState(!playerModalOpenState);
    };

    const toggleErrorCodeModal = () => {
        setErrorCodeModalOpenState(!errorCodeModalOpenState);
    };

    const toggleHelpModal = () => {
        setHelpModalOpenState(!helpModalOpenState);
    };

    const handleChange = (event) => {
        setInputCode(event.target.value);
    };

    const handleSubmit = async (event, manualCode = null) => {
        event.preventDefault();
        let inputCodeToUse = manualCode != null ? manualCode : inputCode.trim();
        let inputCodeData = await checkCode(inputCodeToUse, codeList, executedCodes, player, opponents);

        if(inputCodeData.status) {
            dispatch(setCurrentCode(inputCodeData.data));

            if(inputCodeData.data.confirmable) {
                toggleConfirmModal();
            } else if(inputCodeData.data.hasIntroduction) {
                navigate("/code/introduction", {replace: true});
            } else {
                navigate("/code/decision", {replace: true});
            }
            setInputCode("");
        } else {
            dispatch(setErrorCodeMessage(inputCodeData.data.message));
            setInputCode("");
            toggleErrorCodeModal();
        }
    };

    return (
        <DefaultLayoutComponent gameType={gameType} currentRound={currentRound} totalRounds={totalRounds} date={displayDate} navbar={true} title="Entrer le code">
            <div className="location-wrapper">
                <div className={formColor}>
                    <img src={headImg} alt="" />
                </div>
            </div>
            {gameType === "profile" ?
                <div className="code-form-wrapper w-75 mx-auto text-center">
                    <div className={formColor}>
                        <button className="btn btn-lg" type="submit" onClick={(event) => {
                                handleSubmit(event, "PROFIL");
                            }
                        }>CHOISIR UN PROFIL</button>
                    </div>
                 </div>
                :
                 <div className="code-form-wrapper w-75 mx-auto text-center">
                    <div className={formColor}>
                        <h4 className="mb-3">Entrez le code :</h4>
                        <div className="mb-3">
                            <input type="text" className="form-control" value={inputCode} onChange={handleChange} onKeyPress={(e) => {if(e.key === 'Enter') handleSubmit(e);}} />
                        </div>
                        <button className="btn" type="submit" onClick={handleSubmit}>Valider</button>
                    </div>
                 </div>
            }

            {gameType === "main" && player.walkOnRomaUnlocked && !executedCodes.includes("MARCHE") && (
                <div className="alert alert-success small text-center mx-3" role="alert">
                    Vous pouvez marchez sur Rome avec le code suivant : <strong>MARCHE</strong>
                </div>
            )}

            <div className="w-100 text-center px-3">
                <h1 className="display-5 stone-light mb-4"><span>CAPAX IMPERI</span></h1>
                {gameType === "tuto" &&
                    <>
                        <button className="hk-btn hk-btn-light mx-auto mb-1" onClick={() => toggleHelpModal()}>
                            <FontAwesomeIcon icon={faQuestion} />
                        </button>
                        <p className="text-black-50 mb-0">
                            <small>Consigne</small>
                        </p>
                    </>
                }
                {gameType === "main" &&
                    <div className="row gx-0 justify-content-around">
                        <div className="col-4">
                            <button className="hk-btn hk-btn-light merites" onClick={() => togglePlayerModal()}>
                                <StaticImage src="../assets/images/merites.png" alt="" />
                            </button>
                            <p className="text-muted mb-0">
                                <small>Vos mérites</small>
                            </p>
                        </div>
                        <div className="col-4">
                            <button className="hk-btn hk-btn-light histoire" onClick={() => toggleStoryModal()}>
                                <StaticImage src="../assets/images/histoire.png" alt="" />
                            </button>
                            <p className="text-muted mb-0">
                                <small>L'histoire</small>
                            </p>
                        </div>
                        <div className="col-4">
                            <button className="hk-btn hk-btn-light adversaires" onClick={() => toggleOpponentsModal()}>
                                <StaticImage src="../assets/images/adversaires.png" alt="" />
                            </button>
                            <p className="text-muted mb-0">
                                <small>Adversaires</small>
                            </p>
                        </div>
                    </div>
                }
            </div>

            <ModalComponent modalId="confirmationModal" modalType="Confirm" fullscreen={true} open={confirmModalOpenState}/>
            <ModalComponent modalId="errorCodeModal" modalType="ErrorCode" open={errorCodeModalOpenState}/>
            <ModalComponent modalId="storyModal" modalType="Story" fullscreen={true} open={storyModalOpenState}/>
            <ModalComponent modalId="opponentsModal" modalType="Opponents" fullscreen={true} open={opponentsModalOpenState}/>
            <ModalComponent modalId="playerModal" modalType="Player" fullscreen={true} open={playerModalOpenState}/>
            {gameType === 'tuto' && <ModalComponent modalId="helpModal" modalType="Help" fullscreen={true} open={helpModalOpenState}/>}
        </DefaultLayoutComponent>
    )
};

export default connect(state => ({
    isNewState: state.global.codeList == null,
    roundsData: state.global.roundsData,
    currentRound: state.global.currentRound,
    totalRounds: state[state.global.type].totalRounds,
    codeList: getCodesByType(state.global.codeList, state.global.type),
    currentCode: state.global.currentCode,
    executedCodes: state.global.executedCodes,
    hasStoryTelling: state[state.global.type].hasStoryTelling,
    gameType: state.global.type,
    player: state[state.global.type].player != null && (state[state.global.type].player),
    opponents: state[state.global.type].opponents != null && state[state.global.type].opponents
}), null)(CodePage)
