const loadChecker = file => import(`../../conditionCheckers/${file}ConditionChecker.js`);

/**
 * checks conditions that are located under "conditions" key in codes json
 * @param conditions
 * @param opponents
 * @param player
 * @returns {Promise<{status: boolean}|*>}
 */
export const checkCodeConditions = async (conditions, opponents, player) => {
    let conditionStatus = null;
    const setConditionStatus = (newConditionStatus) => {
        conditionStatus = newConditionStatus;
    };

    if (conditions != null) {
        for(let i = 0; i < conditions.length; i++) {
            let condition = conditions[i];
            for(let j = 0; j < condition.checks.length; j++) {
                let conditionType = condition.checks[j];

                const checkerFile = loadChecker(conditionType);
                await checkerFile.then(({checkCondition}) => {
                    let checkerStatus = checkCondition({opponent: opponents[condition.name], player});
                    if(!checkerStatus.status) setConditionStatus(checkCondition({opponent: opponents[condition.name], player}));
                })
            }
        }
    }

    if(conditionStatus != null && !conditionStatus.status){
        return conditionStatus;
    }

    return {status: true};
};
